document.addEventListener('DOMContentLoaded', () => {
    function getQueryParam(param) {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get(param);
    }

    function setMetaTag() {
        const isApp = getQueryParam('isApp');
        const metaTag = document.createElement('meta');
        metaTag.name = 'oel-is-app';
        metaTag.content = false;
        if(isApp) {
            metaTag.content = isApp.toLowerCase() === 'true';
        }
        else {
            metaTag.content = false;
        }
        document.head.appendChild(metaTag);
    }

    setMetaTag();
});