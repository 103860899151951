import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "@dsd/library/dist/library/library.css";
import { BrowserRouter as Router } from "react-router-dom";
import { applyPolyfills, defineCustomElements } from "@dsd/library/dist/loader";
import "./scripts/setMetaTag";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App />
    </Router>
  </React.StrictMode>
);

applyPolyfills().then(() => {
  defineCustomElements(window);
});
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
